.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.plug{
  position: absolute;
  top: 30px;
  left: 60px;
  margin: 0;
  color: #F00062;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
}

section.description{
  display: flex;
  align-items: center;
  width: 100%;
  padding: 60px;
}

.description article, .description aside{
  width: 50%;
}

.description article{
  text-align: left;
}

h2{
  font-size: 50px;
  margin: 20px 0;
}

p{
  font-size: 17px;
}

aside{
  padding-left: 70px;
}
aside img{
  width: 100%;
}
.FooButton{
  margin-top: 20px;
}



@media screen and (max-width: 840px) {

  section.description{
    flex-direction: column;
    overflow-y: scroll;
    padding: 90px 60px;
    align-items: flex-start;
    height: 100%;
  }
  .description article, .description aside{
    width: 100%;
    padding: 0;
  }
  aside{
    margin-top: 50px;
  }
  aside img{
    width: 100%;
  }

  .plug{
    display: none;
  }
}
