.FooButton{
    color: white;
    background: #F00062;

    border: none;
    border-radius: 50px;

    font-size: 15px;

    width: auto;
    padding: 0 25px;
    height: 60px;
    cursor: pointer;
}

.FooButton:hover{
    filter: brightness(1.1);
}