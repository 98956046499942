.NameLabel{
    display: flex;
    font-size: 30px;
}


.NameLabel .mask{
    height: 100%;
    width: max-content;
    position: absolute;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    color: #F00062;
}
.mask, .label{
    cursor: pointer;
}
.label{
    margin: 0;
    transform: translateY(0%);
    transition: transform 500ms ease-in-out, opacity 300ms ease-in-out;
}
.mask:hover .title{
    opacity: 1;
}
.mask:hover .label{
    transform: translateY(-100%);
}
.label.title, .mask:hover .name{
    opacity: 0;
}